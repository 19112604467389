<template>
  <div class="collection-wrapper">
    <div class="background-image">
      <img src="../img/bg.png" alt="" />
    </div>

    <div id="nav">
      <router-link
        class="button"
        :to="{
          name: 'Home',
        }"
      >
        x
      </router-link>
      <div
        class="button"
        @click="toggleImages($event)"
        :class="{ activeButton: !imagesIsCollapsed }"
      >
        <p>Image</p>
      </div>
      <div
        class="button"
        @click="toggleText($event)"
        :class="{ activeButton: !textIsCollapsed }"
      >
        <p>Text</p>
      </div>
    </div>

    <div
      class="collection-container"
      ref="collectionRef"
      @mousemove="stopScroll"
    >
      <div v-if="collection" class="collection">
        <div class="collection_text" v-if="!textIsCollapsed">
          <p class="name">{{ name }}</p>
          <p class="title">{{ collection.titel }}</p>
          <p class="text">{{ collection.text }}</p>

          <p v-if="collection.prof">Betreut von: {{ collection.prof }}</p>
          <p v-if="collection.models">Models: {{ collection.models }}</p>
          <p v-if="collection.styling">Styling: {{ collection.styling }}</p>
          <p v-if="collection.foto">Foto: {{ collection.foto }}</p>
          <p v-if="collection.makeup">Hair & MakeUp: {{ collection.makeup }}</p>
          <br />
          <a
            class="links"
            target="_blank"
            v-if="collection.insta"
            :href="collection.insta"
            >Instagram</a
          >
          <a
            class="links"
            target="_blank"
            v-if="collection.website"
            :href="collection.website"
            >Website</a
          >
          <a
            class="links"
            v-if="collection.mail"
            :href="`mailto:${collection.mail}`"
            >Mail</a
          >
        </div>

        <div class="collection-images" v-if="!imagesIsCollapsed">
          <div v-for="i in numImages" :key="i">
            <img :src="getImg(i)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Collection",
  props: ["name", "showVideo", "showInfo", "showImpressum", "showGallery"],
  data() {
    return {
      shouldAutoScroll: true,
      textIsCollapsed: true,
      imagesIsCollapsed: false,
      getImg: (i) => {
        const folderName = this.name.toLowerCase().replace(" ", "_");
        return require(`../img/${folderName}/${folderName}_${i}.jpg`);
      },
    };
  },
  beforeCreate() {
    if (this.$store.state.collections.length === 0) {
      this.$store.commit("setData");
    }
  },
  computed: {
    collection() {
      return this.$store.state.collections.filter(
        (c) => c.name === this.name
      )[0];
    },
    numImages() {
      let length = 10;
      if (this.name === "Victoria Maraia" || this.name === "Sarah Rabe") {
        length = 8;
      }
      if (
        this.name === "Svea Christiansen" ||
        this.name === "Noa Burnette" ||
        this.name === "Kilian Landwehr" ||
        this.name === "Felix Gumbsch"
      ) {
        length = 9;
      }
      return Array.from({ length }, (_, i) => i + 1);
    },
  },
  methods: {
    toggleText(event) {
      event.stopPropagation();
      this.textIsCollapsed = !this.textIsCollapsed;
    },
    toggleImages(event) {
      event.stopPropagation();
      this.imagesIsCollapsed = !this.imagesIsCollapsed;
    },
  },
};
</script>

<style scoped>
.collection-wrapper {
  height: 100vh;
  overflow: hidden;
}

p {
  margin: 0;
}

.background-image {
  position: absolute;
  height: 100vh;
}

.background-image img {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.collection-container {
  z-index: 20;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.collection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
  gap: 10px;
  overflow-y: scroll;
  font-family: Halibut;
  height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.collection::-webkit-scrollbar {
  display: none;
}

.collection p {
  margin: 0;
  font-variation-settings: "wght" 100, "wdth" 100;
}

.collection_text {
  font-family: Arachne;
  color: var(--green);
  font-size: 2em;
  height: calc(90vh - 1rem);
  overflow: scroll;
  left: 0;
  width: 90vw;
  text-shadow: var(--text-glow);
  position: absolute;
  margin: 1rem 0 0 1rem;
  padding-bottom: 10vh;
  /* opacity: 0.95; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.collection_text::-webkit-scrollbar {
  display: none;
}

.collection-images {
  display: flex;
  gap: 10px;
  margin-left: 1rem;
  align-items: center;
  height: 100vh;
}

#nav {
  user-select: none;
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 1000;
  font-family: var(--text-font);
  padding: 10px;
  bottom: 5px;
  left: 10px;
  cursor: url("../img/ellipse.png") 15 15, pointer !important;
}

.button {
  backdrop-filter: blur(7px);
  text-align: center;
  text-decoration: none;
  color: white;
  /* background-color: #f8f8f8; */
  padding: 4px 20px 6px 20px;
  font-size: 1.4em;
  font-variation-settings: "wght" 100, "wdth" 50;
  transition: all 1s;
  border-radius: 50px;
  margin-right: 10px;
  box-shadow: var(--not-active);
}

.button:hover {
  font-variation-settings: "wght" 100, "wdth" 100;
  border-radius: 7px;
  transition: all 1s;
  cursor: url("../img/ellipse.png") 15 15, pointer !important;
}

.activeButton {
  box-shadow: var(--active);
}

.active {
  display: inline !important;
}

.text {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  font-variation-settings: "wght" 100, "wdth" 50 !important;
}

.links {
  color: var(--green);
  text-decoration: none;
  border-bottom: 0.5px solid var(--green);
  margin-right: 1em;
}

.collection img {
  height: 88vh;
  border-radius: 30px;
  -webkit-box-shadow: 0px -4px 5px 0px #0000001c inset,
    0px 4px 4px 0px #fefefe inset, 0 15px 20px rgba(40, 40, 40, 0.134);
}

@media (max-width: 600px) {
  .collection_text {
    font-size: 1em;
    width: 85vw;
  }
  .collection {
    padding-left: 0;
    height: 100vh;
  }
  .collection img {
    height: 90vh;
  }
  .links {
    margin-bottom: 3em;
  }
}
</style>
